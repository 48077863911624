import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AccessWallComponent } from './access-wall/access-wall.component'
import { BookRouteComponent } from './book-route/book-route.component'
import { ChapterPlaceholderComponent } from './chapter-placeholder/chapter-placeholder.component'
import { ChapterRouteComponent } from './chapter-route.component'
import {
  MaterialAccessConfig,
  MaterialAccessGuard,
} from './material-access.guard'
import { MaterialDefaultLocaleGuard } from './material-default-locale.guard'
import { ViewerResolver } from './viewer.resolver'

export enum MaterialBookSlug {
  en = 'englisch',
  de = 'deutsch',
  lv = 'lettisch',
  lt = 'litauisch',
}

const routes: Routes = [
  {
    path: 'zugang',
    component: AccessWallComponent,
    canActivate: [MaterialAccessGuard],
    data: {
      activateIf: 'hasNoAccess',
      redirectTo: '/material',
    } as MaterialAccessConfig,
  },
  {
    path: 'Deutsch',
    pathMatch: 'full',
    redirectTo: 'deutsch',
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: [MaterialDefaultLocaleGuard],
    component: BookRouteComponent,
  },
  {
    path: ':bookSlug',
    component: BookRouteComponent,
    canActivate: [MaterialAccessGuard],
    data: {
      activateIf: 'hasAccess',
      redirectTo: '/material/zugang',
    } as MaterialAccessConfig,
    resolve: {
      viewer: ViewerResolver,
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    children: [
      {
        path: '',
        component: ChapterPlaceholderComponent,
      },
      {
        path: ':levelSlug',
        component: ChapterPlaceholderComponent,
      },
      {
        path: ':levelSlug/:parentChapterSlug',
        component: ChapterRouteComponent,
      },
      {
        path: ':levelSlug/:parentChapterSlug/:chapterSlug',
        component: ChapterRouteComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MaterialRoutingModule {}
