<nav class="navbar">
  <div class="navbar-brand">
    <a routerLink="/" class="navbar-item" title="Startseite">
      <span class="icon mr-4"><img src="assets/icons/icon-256x256.png" /></span>
      <ng-container i18n>KdGH Mitgliederportal</ng-container>
    </a>

    <a class="navbar-burger" role="button" aria-label="Hauptmenü öffnen">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div class="navbar-menu">
    <div class="navbar-start">
      <a
        class="navbar-item"
        routerLink="{{ materialLink }}"
        routerLinkActive="is-active"
        i18n
        >Material</a
      >
    </div>

    <div class="navbar-end">
      <div
        class="navbar-item has-dropdown locale-selection"
        *ngIf="showLocalSelector"
      >
        <a class="locale-menu navbar-link">
          <span class="locale-menu-label">
            <ng-container i18n>Sprache</ng-container>
            <span class="locale-flag-icon"
              ><img src="{{ localFlagSvg }}"
            /></span>
          </span>

          <div class="navbar-dropdown is-right">
            <a
              class="navbar-item"
              (click)="selectLocale(AppLocale.de)"
              [class.is-selected]="locale === AppLocale.de"
              i18n
            >
              <img src="assets/icons/flags/de.svg" />
              Deutsch
            </a>
            <a
              class="navbar-item"
              (click)="selectLocale(AppLocale.en)"
              [class.is-selected]="locale === AppLocale.en"
              i18n
            >
              <img src="assets/icons/flags/gb.svg" />
              Englisch
            </a>
            <a
              class="navbar-item"
              (click)="selectLocale(AppLocale.lt)"
              [class.is-selected]="locale === AppLocale.lt"
              i18n
            >
              <img src="assets/icons/flags/lt.svg" />
              Litauisch
            </a>
            <a
              class="navbar-item"
              (click)="selectLocale(AppLocale.lv)"
              [class.is-selected]="locale === AppLocale.lv"
              i18n
            >
              <img src="assets/icons/flags/lv.svg" />
              Lettisch
            </a>
          </div>
        </a>
      </div>

      <ng-template #signInButton>
        <div class="navbar-item">
          <button class="button is-primary" (click)="signIn()" i18n>
            Anmelden
          </button>
        </div>
      </ng-template>

      <div
        *ngIf="(userService.isSignedUp$ | async) === true; else signInButton"
        class="navbar-item has-dropdown"
      >
        <a
          class="navbar-link field is-grouped mb-0"
          i18n-aria-label
          aria-label="Nutzerkonto Menu öffnen"
        >
          <div class="control">
            <span class="icon is-medium"
              ><i class="fas fa-user-circle fa-lg"></i
            ></span>
          </div>
          <div class="control">
            <strong class="level-item">{{
              userService.displayName$ | async
            }}</strong>
          </div>
        </a>

        <div class="navbar-dropdown is-right">
          <a class="navbar-item" (click)="signOut()" i18n>Abmelden</a>
        </div>
      </div>
    </div>
  </div>
</nav>
